import React from 'react';
import {Card, Container, Grid,Typography} from "@mui/material";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';


const styles = {
    card: {
        marginLeft: {md: "45px", sm: "15px", xs: "5px"},
        marginRight: {md: "45px", sm: "15px", xs: "5px"},
        padding: {md: "20px", sm: "15px", xs: "15px"},
        display: {md: 'flex', xs: {type: 'flex', flexDirection: 'row-reverse'}}
    },
    container: {
        backgroundColor: '#F0EAD6',
        paddingTop: '15px',
        paddingBottom: '300px',
        height: 'auto',
    },
    image: {
        marginRight: '5px',
        marginBottom: {
            xs: '5px',
            sm: '5px',
            md: '0px',
            lg: '0px'
        }
    },
    hideMobile: {
        display: {
            lg: 'block',
            md: 'block',
            sm: 'none',
            xs: 'none'
        },
        marginLeft: '20px',

    },
    hideDesktop: {
        display: {
            lg: 'none',
            md: 'none',
            sm: 'block',
            xs: 'block'
        }
    },
    tableCell: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#80008066',
            color: 'white'
        },
    },
    tableHeader: {
        backgroundColor: '#80008066',
    }
}
const Commercial = () => {
    return (
        <Container sx={styles.container} maxWidth={false}>
            <Card sx={styles.card}>
                <Grid container spacing={2} flexDirection={'column'}>
                    <Grid item>
                        <Typography variant={'h3'} sx={{fontSize: {md: 30, xs: 24}}}>
                            Commercial Scented Products
                        </Typography>
                    </Grid>
                    <Grid container alignItems={'flex-start'} justifyContent={'center'}>
                        <Grid item sx={styles.image}>
                            <img src={'/commercialLeft.jpg'} alt={'test'}/>
                        </Grid>
                        <Grid item>
                            <img src={'/commercialMiddle.jpg'} alt={'test'}/>
                        </Grid>
                        <Grid item>
                            <img src={'/commercialRight.jpg'} alt={'test'}/>
                        </Grid>
                        <Grid item>
                            <Grid container width={'100%'}>
                                <Table sx={styles.hideDesktop}>
                                    <TableRow >
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Advertisements</TableCell>
                                        <TableCell>Greeting Cards - Corporate</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Air Fresheners</TableCell>
                                        <TableCell>Hand-Outs</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Announcements</TableCell>
                                        <TableCell>Package Inserts</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Book Marks</TableCell>
                                        <TableCell>Postcards</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Bounce Backs</TableCell>
                                        <TableCell>Product Packaging</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Brochures</TableCell>
                                        <TableCell>Promotional Specialties & "One - Offs"</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Coupons</TableCell>
                                        <TableCell>Publication Inserts</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Direct Mail Pieces</TableCell>
                                        <TableCell>Sachets</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Event Invitations</TableCell>
                                        <TableCell>Scent Samplers</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Flyers</TableCell>
                                        <TableCell>Stickers</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Follow-up Media</TableCell>
                                        <TableCell>Take-Ones</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Fragrance Labels</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </Table>
                                <Table sx={styles.hideMobile}>
                                    <TableRow >
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell >Advertisements</TableCell>
                                        <TableCell>Event Invitations</TableCell>
                                        <TableCell>Product Packaging</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Air Fresheners</TableCell>
                                        <TableCell>Flyers</TableCell>
                                        <TableCell>Promotional Specialties and "One - Offs"</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Announcements</TableCell>
                                        <TableCell>Follow-up Media</TableCell>
                                        <TableCell>Publication Inserts</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Book Marks</TableCell>
                                        <TableCell>Fragrance Labels</TableCell>
                                        <TableCell>Sachets</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Bounce Backs</TableCell>
                                        <TableCell>Greeting Cards</TableCell>
                                        <TableCell>Scent Samplers</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Brochures</TableCell>
                                        <TableCell>Hand-Outs</TableCell>
                                        <TableCell>Stickers</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Coupons</TableCell>
                                        <TableCell>Package Inserts</TableCell>
                                        <TableCell>Take-Ones</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Direct Mail Pieces</TableCell>
                                        <TableCell>Postcards</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </Table>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Container>
    )
}

export default Commercial;