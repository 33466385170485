import React from 'react';
import {Card, Container, Grid, Typography} from "@mui/material";

const styles = {
    card: {
        marginLeft: {md: "45px", sm: "15px", xs: "5px"},
        marginRight: {md: "45px", sm: "15px", xs: "5px"},
        padding: {md: "20px", sm: "15px", xs: "15px"},
        display: {md: 'flex', xs: {type: 'flex', flexDirection: 'row-reverse'}}
    },
    container: {
        backgroundColor: '#F0EAD6',
        paddingTop: '15px',
        paddingBottom: '300px',
        height: 'auto',
    }
}
const Home = () => {
    return (
        <Container sx={styles.container} maxWidth={false}>

            <Card sx={styles.card}>
                <Grid container spacing={2}>

                    <Grid item md={12} lg={8} sm={12} xs={12} order={{xs: 2, sm: 2, md: 2, lg: 1}}>
                        <Typography variant={'h1'} sx={{ml: '0px', mb: '5px', fontSize: {md: 40, xs: 24}}}>
                        Welcome to The Scent-It Palette
                        </Typography>
                        <Typography variant={'body1'} sx={{marginBottom: "15px"}}>
                            Scent Marketing Experts and Leaders in the Fragrance Sampling Industry.
                        </Typography>

                        <Typography variant={'body1'} sx={{marginBottom: "15px"}}>
                            Our team of experienced professionals has been in the printing, fragrance and scent
                            marketing industry for decades, and together have made major contributions to the
                            technologies that exist in the industry today.
                        </Typography>

                        <Typography variant={'body1'} sx={{marginBottom: "15px"}}>
                            We offer a wide variety of fragrances and scent sampling products, while continuing to
                            develop new and exciting scent delivery formats. Our versatility, experience and
                            capabilities enable us to offer economical options for orders of all quantities.
                        </Typography>

                        <Typography variant={'body1'} sx={{marginBottom: "15px"}}>
                            From simple projects to complex marketing plans, The Scent-It Palette is capable of managing
                            all of your production needs.
                        </Typography>

                        <Typography variant={'body1'} sx={{marginBottom: "15px"}}>
                            We look forward to working with you and helping grow your business, and welcome the
                            opportunity to collaborate on any of your current or upcoming projects.
                        </Typography>

                        <Typography variant={'body1'} sx={{marginBottom: "15px"}}>
                            How can you easily increase the power and effectiveness of your next Marketing campaign? The
                            answer is quite simple. Scent-It!
                        </Typography>
                    </Grid>
                    <Grid item md={12} lg={4} sm={12} xs={12} order={{xs: 1, sm: 1, md: 1, lg: 2}}>
                        <img src={"/Scent-It-Logo.png"} alt={'Scent It Palette Logo'}
                             style={{height: "auto", width: '100%'}}/>
                    </Grid>
                </Grid>
            </Card>
        </Container>
    );
}
export default Home;
