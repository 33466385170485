import React from 'react';
import {Card, Container, Grid, Typography} from "@mui/material";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const styles = {
    card: {
        marginLeft: {md: "45px", sm: "15px", xs: "5px"},
        marginRight: {md: "45px", sm: "15px", xs: "5px"},
        padding: {md: "20px", sm: "15px", xs: "15px"},
        display: {md: 'flex', xs: {type: 'flex', flexDirection: 'row-reverse'}}
    },
    container: {
        backgroundColor: '#F0EAD6',
        paddingTop: '15px',
        paddingBottom: '300px',
        height: 'auto',
    }
}

const AboutUs = () => {
    return (
        <Container sx={styles.container} maxWidth={false}>

            <Card sx={styles.card}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant={'h3'} sx={{fontSize: {md: 30, xs: 24}, mb: '15px'}}>
                            About Us
                        </Typography>
                        <Typography variant={'body1'} sx={{mb: '15px'}}>
                            The Scent-It Palette team has a strong background in Scent Marketing, Fragrance Products,
                            Product Design, Print Production, Mailing and Fulfillment Services. Our core group of
                            talented professionals collectively bring decades of “hands-on” experience in Scent
                            Marketing, Fragrance Technology, Advertising, Manufacturing, Production Management, Format
                            Design and Product Development.
                        </Typography>
                        <Typography variant={'body1'} sx={{mb: '15px'}}>
                            The Scent-It Palette Fragrance Techs have been creating fragrance scented products since the
                            1980’s, and have provided a variety of scent sampling products such as Snap & Burst for
                            traditional fragrance strip formats most commonly used in publications, Scented Page Varnish
                            for fragrance sampling spots that require activation by scratching or rubbing, Scent
                            Coatings, and a variety of Fragrance Labels for direct mail and product packaging.
                        </Typography>
                        <Typography variant={'body1'} sx={{mb: '15px'}}>
                            Some of our collective experience working with manufacturers include Webcraft/Vertis, RR
                            Donnelley, Quad Graphics, Mars Graphics Services (formerly Harte-Hanks), Delta Graphics,
                            Amidon Graphics, Cyril Scott and Cenveo.
                        </Typography>
                        <Typography variant={'body1'} sx={{mb: '15px'}}>
                            Among our many achievements throughout the years, the U.S. Post Master General has awarded
                            our President of Fragrance Sales and Marketing with the “USPS Commercial Business
                            Development Award”, while co-founding a scent marketing division for a print brokerage firm
                            in New York City.
                        </Typography>
                        <Typography variant={'body1'} sx={{mb: '15px'}}>
                            Fragrance companies that have worked with our chemists and have been provided with scented
                            products include Annika, Arbonne, Avon Products, Calvin Klein, Caswell-Massey, Chanel,
                            Crabtree & Evelyn, Escada, Estee Lauder, Halston, L’Oreal, Oleg Cassini, Perry Ellis, and
                            Unilever, while we continue to expand our customer relationships.
                        </Typography>
                        <Typography variant={'body1'} sx={{mb: '15px'}}>
                            With our fresh ideas and technical expertise, our goal is to help our customers stand-out
                            from the rest, in expanding, ever-changing and competitive markets. By utilizing the most
                            effective and creative methods of Scent Marketing, The Scent-It Palette will help fortify
                            your corporate image while promoting brand loyalty for your products and services.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container flexDirection={'row'}>
                            <Grid item>
                                <img src={'aboutUs1.jpg'} alt={'aboutUs'}/>
                            </Grid>
                            <Grid item sx={{ml: { md: '20px', lg: '20px'}}}>
                                <Typography variant={'h3'}  sx={{fontSize: '18px', marginTop: '10px'}} fontWeight={'bold'}>
                                    Production Capabilities
                                </Typography>
                                <Table>
                                    <TableRow>
                                        <TableCell>Heat-set Web Inline Finishing</TableCell>
                                        <TableCell>Printing with Offset or UV</TableCell>
                                        <TableCell>Silk Screen</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Sheet fed</TableCell>
                                        <TableCell>Digital</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </Table>
                                <Typography variant={'h3'}  sx={{fontSize: '18px', marginTop: '20px'}} fontWeight={'bold'}>
                                    Mailing Services
                                </Typography>
                                <Table>
                                    <TableRow>
                                        <TableCell>Data Processing for Mail Optimization</TableCell>
                                        <TableCell>Bulk & Drop Mailings</TableCell>
                                        <TableCell>Pick & Pack</TableCell>
                                    </TableRow>
                                </Table>
                                <Typography variant={'h3'}  sx={{fontSize: '18px', marginTop: '20px'}} fontWeight={'bold'}>
                                    Interactive Fragrance Sampling Products
                                </Typography>
                                <Table>
                                    <TableRow>
                                        <TableCell>Snap & Burst</TableCell>
                                        <TableCell>Scented Coatings</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Rub & Smell</TableCell>
                                        <TableCell>Scratch & Sniff</TableCell>
                                    </TableRow>
                                </Table>
                                <Typography variant={'h3'}  sx={{fontSize: '18px', marginTop: '20px'}} fontWeight={'bold'}>
                                    Scent Sampling Uses for Marketing, Advertising and Product
                                </Typography>
                            </Grid>

                        </Grid>
                        <Grid container sx={{mt: '20px'}}  flexDirection={'row'} justifyContent={'space-between'} alignContent={'flex-start'}>
                            <Grid item order={{sm: 2, xs: 2, lg: 1, md: 1}}>
                                <Typography variant={'h3'}  sx={{fontSize: '18px'}} fontWeight={'bold'}>
                                    Branding
                                </Typography>
                                <Table>
                                    <TableRow>
                                        <TableCell>Direct Mail: Brochures, Flyers, Coupons, Inserts, Cards, Postcards</TableCell>
                                        <TableCell>Cards with Fragrance Labels</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{width: {sm:'145px', xs: '145px', lg: 'auto', md: 'auto'}}}>Catalogs: Rub & Smell, Scratch & Sniff, Scented Page Varnish</TableCell>
                                        <TableCell>Publication Inserts</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Outer wraps</TableCell>
                                        <TableCell>Newspaper Inserts</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Take-ones</TableCell>
                                        <TableCell>Blow-ins</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Package Inserts</TableCell>
                                        <TableCell>Snap & Burst Cards</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Bounce-Backs</TableCell>
                                        <TableCell>Business Cards</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Scented Product Packaging</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </Table>
                            </Grid>
                            <Grid item sx={{ml: {lg: '20px', md: '20px'}, mt: {sm: '5px', xs: '5px'}}} order={{sm: 1, xs: 1, md: 2, lg: 2}}>
                                <img src={'aboutUs2.jpg'} alt={'aboutus'} height={'auto'} width={'100%'}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Container>
    )
}

export default AboutUs;