import React from 'react';
import {Container, Grid, Typography} from '@mui/material';

const styles = {
    container: {
        marginTop: '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'space-around',
        flexDirection: 'column',
        width: '100%',
        height: "60px",
        marginBottom: "10px",
        textAlign: 'center'

    }
}
const Footer = () => {
    return (
        <Container sx={styles.container}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item>
                    <Typography variant={'body1'} sx={{marginBottom: '5px'}}>
                        ~ Offices located throughout Southern and Central New Jersey ~
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant={'body2'}>
                        © 2013-2023 The Scent-It Palette LLC, all rights reserved
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Footer;