import React from 'react';
import './App.css';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Header from "./Header";
import { BrowserRouter, Routes, Route  } from "react-router-dom";
import Home from "./Home";
import Products from "./Products";
import Commercial from "./Commercial";
import Fragrances from "./Fragrances";
import Business from "./Business";
import AboutUs from "./AboutUs";
import Contact from "./Contact";
import Footer from "./Footer";

// declare module "@mui/material/styles" {
//     interface Theme {
//         customPalette: {
//             customSecondary: string;
//             customPrimary: string;
//         };
//     }
//     interface ThemeOptions {
//         customPalette?: {
//             customSecondary?: string;
//             customPrimary?: string;
//         };
//     }
// }
const theme = createTheme({
    palette: {
        primary: {
            main: '#800080BB',
            light: '#00FF00',
            dark: '#FF0000'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                },
                h1: {
                    fontSize: 50,
                    font: 'italic 2.5em serif',
                    // marginTop: "15px",
                    marginLeft: "15px",
                }
            }
        },
        // MuiDrawer: {
        //     styleOverrides: {
        //         root: {
        //             backgroundColor: 'purple'
        //         },
        //         paper: {
        //             backgroundColor: 'green'
        //         }
        //     }
        // }

    }
});

const App = () => {
  return (
      <ThemeProvider theme={theme}>
          <BrowserRouter>
              <Header />
              <Routes>
                  <Route index element={<Home />} />
                  <Route path="/products" element={<Products />} />
                  <Route path="/fragrances" element={<Fragrances />} />
                  <Route path="/commercial" element={<Commercial />} />
                  <Route path="/business" element={<Business />} />
                  <Route path="/aboutus" element={<AboutUs />} />
                  <Route path="/contact" element={<Contact />} />
              </Routes>
          </BrowserRouter>
          <Footer/>
      </ThemeProvider>
  );
}

export default App;
