import React from 'react';
import { Card, Container, Grid,Typography} from "@mui/material";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const styles = {
    card: {
        marginLeft: {md: "45px", sm: "15px", xs: "5px"},
        marginRight: {md: "45px", sm: "15px", xs: "5px"},
        padding: {md: "20px", sm: "15px", xs: "15px"},
        display: {md: 'flex', xs: {type: 'flex', flexDirection: 'row-reverse'}}
    },
    container: {
        backgroundColor: '#F0EAD6',
        paddingTop: '15px',
        paddingBottom: '300px',
        height: 'auto',
    },
    image: {},
    hideMobile: {
        display: {
            lg: 'block',
            md: 'block',
            sm: 'none',
            xs: 'none'
        },
        marginLeft: '20px',

    },
    hideDesktop: {
        display: {
            lg: 'none',
            md: 'none',
            sm: 'block',
            xs: 'block'
        }
    },

}
const Business = () => {
    return (
        <Container sx={styles.container} maxWidth={false}>
            <Card sx={styles.card}>
                <Grid container spacing={2} flexDirection={'column'} justifyContent={'center'}>
                    <Grid item>
                        <Typography variant={'h3'} sx={{fontSize: {md: 30, xs: 24}, mb: '10px'}}>
                            Small Business Scented Products
                        </Typography>
                    </Grid>

                    <Grid container alignItems={'flex-start'} spacing={5}>
                        <Grid item lg={6} md={6} sm={12} xs={12} order={{xs: 2, sm: 2, md: 1, lg: 1}} sx={{ml: '15px'}}>
                            <Typography variant={'body1'} sx={{mb: '10px'}}>
                                "The Scent-It Palette" products can be used in many different ways!
                            </Typography>
                            <Typography variant={'body1'} sx={{mb: '10px'}}>
                                These scented formats are made with a variety of fragrance products such as: Scented
                                Coatings that emit scent automatically, Scratch & Sniff (Rub & Smell) which needs
                                activation, scented stickers/labels, and Snap & Burst flap formats that you normally
                                see
                                used for fragrance Ads in magazines.
                            </Typography>
                            <Typography variant={'body1'} sx={{mb: '5px'}}>
                                There are so many great ways to make you stand out from the rest!
                            </Typography>
                            <Typography variant={'body1'} sx={{mb: '5px'}}>
                                Some of our available products:
                            </Typography>
                            <Table>
                                <TableRow>
                                    <TableCell>Advertisements</TableCell>
                                    <TableCell>Mailers</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Air Fresheners</TableCell>
                                    <TableCell>Memo Pads</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Announcements</TableCell>
                                    <TableCell>Package Inserts</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Book Marks</TableCell>
                                    <TableCell>Postcards</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Brochures</TableCell>
                                    <TableCell>Promotional Specialties</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Coupons</TableCell>
                                    <TableCell>Scent Samplers</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Flyers</TableCell>
                                    <TableCell>Stickers</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Greeting Cards</TableCell>
                                    <TableCell>Take-Ones</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Hand-Outs</TableCell>
                                    <TableCell>Wrist Bracelets</TableCell>
                                </TableRow>
                            </Table>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12} order={{xs: 1, sm: 1, md: 2, lg: 2}}
                              sx={{ml: '15px', mr: '5px'}}>
                            <img src={'/business.jpg'} alt={'business'} style={{width: '100%', height: 'auto'}}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Container>
    )
}

export default Business;