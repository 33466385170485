import React from 'react';
import {MenuItem, Typography} from "@mui/material";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import {Link} from 'react-router-dom';
import {useTheme} from "@mui/material/styles";
import {useLocation} from 'react-router-dom';

const styles = {
    menuIcon: {
        color: "#FFF"
    },
    header: {
        font: 'italic 2.5em serif'
    },
    subheader: {
        font: 'italic 1.5em serif'
    },
    telephone: {
        underline: 'hover',
        marginLeft: 'auto',
        font: 'italic 1.5em serif'
    },
    link: {
        textDecoration: 'none',
        width: '100%',
        marginRight: '10px',
        color: "#FFF",
        textTransform: 'none',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '15px',
        paddingRight: '15px'
    },
    linkMobile: {
        textDecoration: 'none',
        width: '100%',
        color: "#FFF"
    }
}

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const drawerWidth = 240;

const Header = (props: Props) => {
    const {window} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const theme = useTheme();

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };


    const location = useLocation()
    const renderSelected = (route: string) => {
        return location.pathname === route ? {
            backgroundColor: theme.palette.primary.main,
            opacity: '100%',
            '&:hover': {backgroundColor: theme.palette.primary.main}
        } : {};
    }

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{textAlign: 'center'}}>
            <Typography variant="h6" sx={{my: 2, color: '#FFF'}}>
                The Scent-It Palette
            </Typography>
            <Divider/>
            <List>
                <ListItem key={'home'} sx={renderSelected('')}>
                    <img src={'homeIcon.png'} alt={'home icon'} height={50} width={50}/>
                    <MenuItem component={Link} to={'/'} sx={styles.linkMobile}>Home</MenuItem>
                </ListItem>
                <ListItem key={'products'} sx={renderSelected('/products')}>
                    <img src={'productsIcon.png'} alt={'home icon'} height={50} width={50}/>
                    <MenuItem component={Link} to={'/products'} sx={styles.linkMobile}>Products</MenuItem>
                </ListItem>
                <ListItem key={'fragrances'} component={Link} to={'/fragrances'} sx={renderSelected('/fragrances')}>
                    <img src={'fragranceIcon.png'} alt={'home icon'} height={50} width={50}/>
                    <MenuItem component={Link} to={'/fragrances'} sx={styles.linkMobile}>Fragrances</MenuItem>
                </ListItem>
                <ListItem key={'commercial'} component={Link} to={'/commercial'} sx={renderSelected('/commercial')}>
                    <img src={'commercialIcon.png'} alt={'home icon'} height={50} width={50}/>
                    <MenuItem component={Link} to={'/commercial'} sx={styles.linkMobile}>Commercial</MenuItem>
                </ListItem>
                <ListItem key={'business'} component={Link} to={'/business'} sx={renderSelected('/business')}>
                    <img src={'businessIcon.png'} alt={'home icon'} height={50} width={50}/>
                    <MenuItem component={Link} to={'/business'} sx={styles.linkMobile}>Business</MenuItem>
                </ListItem>
                <ListItem key={'aboutus'} sx={renderSelected('/aboutus')}>
                    <img src={'aboutUsIcon.png'} alt={'home icon'} height={50} width={50}/>
                    <MenuItem component={Link} to={'/aboutus'} sx={styles.linkMobile}>About Us</MenuItem>
                </ListItem>
                <ListItem key={'contact'} sx={renderSelected('/contact')}>
                    <img src={'contactIcon.png'} alt={'home icon'} height={50} width={50}/>
                    <MenuItem component={Link} to={'/contact'} sx={styles.linkMobile}>Contact</MenuItem>
                </ListItem>
            </List>
        </Box>
    );


    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar component="nav" position={"sticky"}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{display: {sm: 'none'}}}
                    >
                        <MenuIcon/>
                        <Typography variant={'body1'} sx={{marginLeft: "5px", fontStyle: "italic"}}>The Scent-It
                            Palette</Typography>
                    </IconButton>
                    <Typography
                        variant="h1"
                        sx={{display: {xs: 'none', sm: 'block'}, mr: '15px'}}
                    >
                        <Typography variant={'body1'} sx={{fontSize: "25px", fontStyle: 'italic'}}>
                            The Scent-It Palette
                        </Typography>
                        <Typography variant={'body2'}>
                            Creative Concepts in Scent Marketing
                        </Typography>
                    </Typography>
                    <Box sx={{display: {xs: 'none', sm: 'inline', md: 'flex', lg: 'flex'}}}>
                        <Button sx={[styles.link, renderSelected("/")]} component={Link} to={"/"}>
                            <img src={'homeIcon.png'} alt={'home icon'} height={50} width={50}/>
                            Home
                        </Button>
                        <Button sx={[styles.link, renderSelected("/products")]} component={Link} to={"/products"}>
                            <img src={'productsIcon.png'} alt={'home icon'} height={50} width={50}/>
                            Products
                        </Button>
                        <Button sx={[styles.link, renderSelected("/fragrances")]} component={Link} to={"/fragrances"}>
                            <img src={'fragranceIcon.png'} alt={'home icon'} height={50} width={50}/>
                            Fragrances
                        </Button>
                        <Button sx={[styles.link, renderSelected("/commercial")]} component={Link} to={"/commercial"}>
                            <img src={'commercialIcon.png'} alt={'home icon'} height={50} width={50}/>
                            <Typography>
                                Commercial
                            </Typography>
                        </Button>
                        <Button sx={[styles.link, renderSelected("/business")]} component={Link} to={"/business"}>
                            <img src={'businessIcon.png'} alt={'home icon'} height={50} width={50}/>
                            Business
                        </Button>
                        <Button sx={[styles.link, renderSelected("/aboutus")]} component={Link} to={"/aboutus"}>
                            <img src={'aboutUsIcon.png'} alt={'home icon'} height={50} width={50}/>
                            About Us
                        </Button>
                        <Button sx={[styles.link, renderSelected("/contact")]} component={Link} to={"/contact"}>
                            <img src={'contactIcon.png'} alt={'home icon'} height={50} width={50}/>
                            Contact
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <nav>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            backgroundColor: theme.palette.primary.main
                        },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
        </Box>
    );
}

export default Header;