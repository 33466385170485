import React, {useRef, useState} from 'react';
import {Button, Card, Container, Grid, TextField, Typography} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const server_url = process.env.REACT_APP_SERVER_URL

const styles = {
    card: {
        marginLeft: {md: "45px", sm: "15px", xs: "5px"},
        marginRight: {md: "45px", sm: "15px", xs: "5px"},
        padding: {md: "20px", sm: "15px", xs: "15px"},
        display: {md: 'flex', xs: {type: 'flex', flexDirection: 'row-reverse'}}
    },
    container: {
        backgroundColor: '#F0EAD6',
        paddingTop: '15px',
        paddingBottom: '300px',
        height: 'auto',
    },
    label: {
        fontWeight: 'bold'
    },
    textField: {
        width: {
            sm: '100%',
            xs: '100%',
            md: '75%',
            lg: '75%'
        },
        marginBottom: '10px'
    }
}


const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const recaptcha = useRef<ReCAPTCHA>(null);

    const disableButton = () => {
        return name === '' || emailInvalid || subject === '' || message === '';
    }

    const handleEmailChange = (event:  React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const text = event.target.value;
        setEmail(text);
        const isValidEmailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        setEmailInvalid(!text.match(isValidEmailRegex))
    }

    const handleSendEmail = async () => {
        setLoading(true);
        const captchaValue = recaptcha.current?.getValue();
        if (!captchaValue) {
            alert("Please verify the reCAPTCHA!");
        } else {

            try {
                const res = await fetch(`${server_url}/verify`, {
                    method: "POST",
                    body: JSON.stringify({captchaValue}),
                    headers: {
                        "content-type": "application/json",
                    },
                });

                const data = await res.json();

                if (data.success) {
                    try {

                        const emailRes = await fetch(`${server_url}/sendEmail`, {
                            method: "POST",
                            body: JSON.stringify({
                                from: email,
                                subject: subject,
                                message: message,
                            }),
                            headers: {
                                "content-type": "application/json"
                            }
                        })
                        if (emailRes.status === 200) {
                            setEmailSent(true);
                        } else {
                            alert("An error occurred sending email. Please contact us at (732) 604-8563 if this issue persists")
                        }
                    } catch (exception) {
                        alert("An error occurred sending email. Please contact us at (732) 604-8563 if this issue persists")
                    }
                } else {
                    alert("reCAPTCHA validation failed!");
                }
            } catch (e) {

            }


        }
        setLoading(false);

    }


    return (
        <Container sx={styles.container} maxWidth={false}>
            {loading &&
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
            {!loading &&
            <Card sx={styles.card}>
                <Grid container>
                    <Grid item>
                        <Typography variant={'h3'} sx={{fontSize: {md: 30, xs: 24}, mb: '15px'}}>
                            Contact Us
                        </Typography>
                    </Grid>
                    {emailSent &&
                        <Grid container>
                            <Grid item>
                                <Typography>Email Successfully Sent</Typography>
                            </Grid>
                        </Grid>

                    }
                    {!emailSent &&
                        <Grid container flexDirection={'column'}>
                    <Grid container>
                        <Typography variant={'body1'} sx={styles.label}>
                            Name
                        </Typography>
                        <Typography sx={{color: 'red'}}>*</Typography>
                    </Grid>
                    <Grid item>
                        <TextField sx={styles.textField} value={name}
                                   onChange={(event) => setName(event.target.value)}></TextField>
                    </Grid>
                    <Grid container>
                        <Typography variant={'body1'} sx={styles.label}>
                            Your email address
                        </Typography>
                        <Typography sx={{color: 'red'}}>*</Typography>
                    </Grid>
                            {emailInvalid &&
                                <Grid container>
                                    <Grid item>
                                        <Typography variant={'body1'} sx={{color: 'red'}}>Please enter a valid email address</Typography>
                                    </Grid>
                                </Grid>
                            }
                    <Grid item>
                        <TextField sx={styles.textField} value={email}
                                   onChange={handleEmailChange}></TextField>
                    </Grid>
                    <Grid container>
                        <Typography variant={'body1'} sx={styles.label}>
                            Subject
                        </Typography>
                        <Typography sx={{color: 'red'}}>*</Typography>
                    </Grid>
                    <Grid item>
                        <TextField sx={styles.textField} value={subject}
                                   onChange={(event) => setSubject(event.target.value)}></TextField>
                    </Grid>

                    <Grid container>
                        <Typography variant={'body1'} sx={styles.label}>
                            Message
                        </Typography>
                        <Typography sx={{color: 'red'}}>*</Typography>
                    </Grid>
                    <Grid item>
                        <TextField sx={styles.textField} multiline={true} rows={3} value={message}
                                   onChange={(event) => setMessage(event.target.value)}/>
                    </Grid>
                    <Grid item>
                        <Button variant={'contained'} sx={{textTransform: 'none', fontWeight: 'bold'}}
                                disabled={disableButton()} onClick={handleSendEmail}>Send</Button>
                    </Grid>
                    <ReCAPTCHA style={{marginTop: '10px'}} ref={recaptcha}
                               sitekey={process.env.REACT_APP_SITE_KEY || ''}/>
                </Grid>
                }
                </Grid>
            </Card>
            }
        </Container>
    )
}

export default Contact;