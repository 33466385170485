import React from 'react';
import {Card, Container, Grid, Typography} from "@mui/material";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const styles = {
    card: {
        marginLeft: {md: "45px", sm: "15px", xs: "5px"},
        marginRight: {md: "45px", sm: "15px", xs: "5px"},
        padding: {md: "20px", sm: "20px", xs: "20px"},
        display: {md: 'flex', xs: {type: 'flex', flexDirection: 'row-reverse'}}
    },
    container: {
        backgroundColor: '#F0EAD6',
        paddingTop: '15px',
        paddingBottom: '100px',
        height: 'auto',
    }
}

const Products = () => {
    return (
        <Container sx={styles.container} maxWidth={false}>
            <Card sx={styles.card}>
                <Grid container spacing={2} flexDirection={'column'}>
                    <Grid item>
                        <Typography variant={'h3'} sx={{fontSize: {md: 30, xs: 24}}}>
                            Fragrance Products
                        </Typography>
                        <Typography sx={{marginTop: '10px'}}>
                            "The Scent-It Palette" products can be used in many different ways! The list below will give
                            you some great ideas for the many possibilities.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h3'} sx={{fontSize: {md: 30, xs: 24}}}>
                            Scented Product Variety
                        </Typography>
                    </Grid>
                    <Grid container flexDirection={'row'} justifyItems={'flex-start'} alignItems={'center'}>
                        <Grid md={8} lg={8} sm={12} xs={12} order={{md: 1, lg: 1, sm: 2, xs: 2}}>
                            <Table cellSpacing={0}>
                                <TableCell colSpan={3}>
                                </TableCell>
                                <TableRow>
                                    <TableCell>Advertisements</TableCell>
                                    <TableCell>Drawer Liners</TableCell>
                                    <TableCell>Publication Inserts</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Air Fresheners (Cars, Lockers & more)</TableCell>
                                    <TableCell>Flyers</TableCell>
                                    <TableCell>Punch Cards</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Announcements</TableCell>
                                    <TableCell>Fragrance Cards - Singles</TableCell>
                                    <TableCell> Sachets</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Bookmarks</TableCell>
                                    <TableCell>Gift Cards</TableCell>
                                    <TableCell>Scented Blotters</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Bounce Backs</TableCell>
                                    <TableCell>Greeting Cards (Corporate or Personal)</TableCell>
                                    <TableCell>Scented Bracelets</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Brochures</TableCell>
                                    <TableCell>Hand-outs</TableCell>
                                    <TableCell>Stationary</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Coupons</TableCell>
                                    <TableCell>Invitations (Promo's and Special Events)</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Direct Mail</TableCell>
                                    <TableCell>Package Inserts</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Door Hangers</TableCell>
                                    <TableCell>Postcard Formats</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </Table>
                        </Grid>
                        <Grid md={4} lg={4} sm={12} xs={12} order={{md: 2, lg: 2, sm: 1, xs: 1}}>
                            <img src={"/ScentedProductVarieties.png"} alt={'Scent Product Varieties'}
                                 style={{height: 'auto', width: '100%'}}/>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h3'} sx={{fontSize: {md: 30, xs: 24}, marginTop: '10px'}}>
                            Choose From a Wide Variety of Scent Delivery Formats
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography  sx={{marginTop: '10px'}}>
                            These scented formats are made with a variety of fragrance products such as: Scented
                            Coatings that emit scent automatically, Scratch & Sniff (Rub & Smell) which needs
                            activation, scented stickers/labels, and Snap & Burst flap formats that you normally see
                            used for fragrance Ads in magazines. Depending on your requirements, we can help you select
                            a Scent Sampling Format that will best fit your needs.
                            We can manufacture a wide variety of Scented Formats to provide consumers with accurate
                            Scent Samples of your product fragrances. These formats differ by how the fragrance is
                            formulated, based on how they are intended to be scent-sampled by consumers.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h3'} sx={{fontSize: '18px', marginTop: '10px'}} fontWeight={'bold'}>
                            "Forever Fragrant" Peel-back Label - USPS APPROVED for Direct Mail!
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            Our patent pending Forever Fragrant labels are perfect for your direct mail efforts, as well
                            as other uses for Marketing and Advertising. Forever Fragrant labels are USPS approved for
                            Direct Mail, and can be used even on the outside of any mail piece without incurring
                            additional postal charges.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            The Forever Fragrant label contains and preserves your product fragrance, until the consumer
                            peels-back the top label ply. This simple interaction engages the consumer immediately, and
                            initiates commitment by piquing their interest.
                        </Typography>
                    </Grid>
                    <Grid item>

                        <Typography>
                            When the top label ply is peeled back, it ruptures the micro fragrance capsules, and
                            delivers an accurate rendition of your fragrance sample. Easy and effective!
                        </Typography>

                    </Grid>
                    <Grid item>
                        <Typography>
                            Unlike the old technology, the Forever Fragrant label offers more area for fragrance, and
                            provides a truer scent rendition. Fragrance labels with old technology use adhesive between
                            the two ply, to hold the label closed and offer reseal-ability. Unfortunately, the adhesive
                            takes up valuable space, where more fragrance can be used. In addition, the smell of the
                            adhesive may be detected by consumers who have a more acute sense of smell.
                        </Typography>
                    </Grid>
                    <Grid item>

                        <Typography>
                            Forever Fragrant labels do not require adhesive to hold the label closed, or offer
                            additional sampling. Our technology was designed to provide multi-scent samplings, without
                            the need (or ambient scent) of adhesives.
                        </Typography>
                    </Grid>
                    <Grid item>

                        <Typography>
                            As Forever Fragrant labels comply with postal regulations, they do not need to be contained
                            within a direct mail piece or envelope. They can be used on the outside of any mail piece
                            such as a postcard or brochure, without incurring additional postal cost. They can either be
                            printed with your preferred copy, or made transparent for application over your pre-printed
                            media, to allow the graphics on the printed piece to show through.
                        </Typography>
                    </Grid>
                    <Grid item>

                        <Typography>
                            You can choose from one of our standard shapes and sizes, or create your own. Supply us with
                            your own essential fragrance oils, or select one from our vast scent library. We will also
                            work with you to help develop a special fragrance.
                        </Typography>
                    </Grid>
                    <Grid item>

                        <Typography>
                            The fragrance oil in the old technology labels will eventually erode through its micro
                            capsules while being stored in inventory for an extended period of time, making the labels
                            useless. The fragrance reaction will also start to develop a cloudy and unappealing
                            appearance as they age, which is not acceptable in any standard of quality. Forever Fragrant
                            labels will last long after the old technology, without clouding or fragrance erosion.
                        </Typography>
                    </Grid>
                    <Grid item>

                        <Typography>
                            Forever Fragrant labels are a perfect Scent Marketing tool for use in various markets. So
                            feel free to take advantage of bulk pricing, and load up your inventory. This would also
                            give you an advantage when working with projects that require quick turn-around delivery
                            schedules!
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h3'} sx={{fontSize: '18px', marginTop: '10px'}} fontWeight={'bold'}>
                            Scented Varnish; Rub & Smell, Scratch & Sniff
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            An encapsulated fragrance product that is printed or screened over select areas in
                            advertisements, that requires interaction by rubbing or scratching to engage the fragrance.
                            This product technology allows consumers the opportunity to sample product fragrances, or
                            connect with other marketing themes
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h3'} sx={{fontSize: '18px', marginTop: '10px'}} fontWeight={'bold'}>
                            Snap & Burst
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            Fragrance flap formats which are most commonly used for publication inserts that are either
                            bound-in or loose blow-ins, where a paper flap is pulled back to reveal the scent sample.
                            These types of inserts are usually found in Magazines and Catalog publications, Newspapers
                            and Package Inserts, as well as hand-outs.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h3'} sx={{fontSize: '18px', marginTop: '10px'}} fontWeight={'bold'}>
                            Fragrance Labels
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            Fragrance labels can be manufactured as Scratch & Sniff or “Peel to Reveal”, and can be
                            manufactured in a variety of shapes and sizes.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h3'} sx={{fontSize: '18px', marginTop: '10px'}} fontWeight={'bold'}>
                            Air Fresheners
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            The Air Freshener product emits fragrance automatically without any interaction. Printed,
                            scented, and die-cut with an elastic string attached, they are then packaged in a clear bag
                            and attached to a header card that is printed, folded and die cut. Most commonly used for
                            Lockers and Vehicles.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={'h3'} sx={{fontSize: '18px', marginTop: '10px'}} fontWeight={'bold'}>
                            Scent-It® Coatings
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            Scent coatings are applied to paper formats in a variety of methods, and emit fragrance
                            automatically. This product technology lends itself to Fragrance Blotters, Scented Wrist
                            Bracelets, Drawer Liners, and Greeting Cards. Scent coated items can be packaged
                            individually or in bulk. Scent-It® Coatings allow the fragrance to emit automatically
                            without consumer interaction such as Rubbing or Scratching. Essential fragrance oils are
                            infused with the paper fibers of your printed media, adding another dimension to your
                            Marketing & Advertising efforts. Through the natural process of evaporation, the fragrance
                            will slowly emit automatically from the printed media, and attract consumers more
                            effectively. They will be influenced by the Power of Scent Marketing!
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography>
                            The Scent-It Palette has developed versatile methods of fragrance application, which will
                            allow us to fragrance any printed piece for you, whether it’s printed with (UV) Ultra Violet
                            inks, or Offset Inks. Depending on your project quantity and budget, Scent-It® Coatings can
                            be applied via Silk Screen, or Roller Coating Equipment.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            Scent-coated pieces can later be packaged individually or in bulk with Scent Barrier film
                            such as reseal-able bags or pouches. This special packaging will help prevent the air from
                            getting in and the fragrance getting out, and ultimately help keep the fragrance fresh while
                            sustaining its level of intensity.
                        </Typography>
                    </Grid>


                    <Grid item>
                        <Typography variant={'h3'} sx={{fontSize: '18px', marginTop: '10px'}} fontWeight={'bold'}>
                            Scent Barrier Coated Envelopes
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            To comply with USPS postal regulations, you can use our Scent Barrier Coated Envelope to
                            mail your scented media. This is a simple solution which will accommodate the USPS non pre
                            odor mail requirements, and successfully get your scented mail pieces delivered.
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography>
                            These special envelopes have a coating applied to the inside of the pocket, which
                            essentially creates a blocking layer across the surface of the paper. The scent barrier
                            coating will help overcome the USPS restrictions by containing the scent of the mail piece,
                            while it also helps keep your fragrance fresh.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            Scent Barrier Coated Envelopes can be ordered from our inventory stock, or made special
                            according to your specifications for printing, envelope size and paper type.
                        </Typography>
                    </Grid>


                    <Grid item>
                        <Typography variant={'h3'} sx={{fontSize: '18px', marginTop: '10px'}} fontWeight={'bold'}>
                            Scent Barrier - Envelope Sleeves
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            Another option for mailing scented media in compliance with the USPS rules and regulations,
                            is our Scent Barrier Envelope Sleeve. It’s a transparent sleeve that has a reseal-able flap.
                            The scented media is first placed inside the transparent sleeve, then inserted into the
                            envelope.
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography>
                            This can be an economical alternative for projects of small quantity, that require an
                            envelope size or type that is not in stock.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography>
                            Scent Barrier Sleeves will keep your fragrance fresh, while increasing the sense of
                            value-added for your mail recipients. The appearance of the extra special Insert will add to
                            the experience, and create a stronger impact for your Scent Marketing efforts.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <hr/>
                    </Grid>
                    <Grid item>
                        <Typography>
                            The Scent-It Palette continues to develop new and exciting scent delivery systems that are
                            effective and economical.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <hr/>
                    </Grid>
                    <Grid item>
                        <Typography>
                            There are so many great ways we can help you stand out from the rest!
                        </Typography>

                    </Grid>
                </Grid>
            </Card>
        </Container>
    )
}
export default Products;