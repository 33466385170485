import React from 'react';
import {Card, Container, Grid, TableHead, Typography} from "@mui/material";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';


const styles = {
    card: {
        marginLeft: {md: "45px", sm: "15px", xs: "5px"},
        marginRight: {md: "45px", sm: "15px", xs: "5px"},
        padding: {md: "20px", sm: "15px", xs: "15px"},
        display: {md: 'flex', xs: {type: 'flex', flexDirection: 'row-reverse'}}
    },
    container: {
        backgroundColor: '#F0EAD6',
        paddingTop: '15px',
        paddingBottom: '300px',
        height: 'auto',
    }
}
const Fragrances = () => {
    return (
        <Container sx={styles.container} maxWidth={false}>
            <Card sx={styles.card}>
                <Grid container flexDirection={'column'}>
                    <Grid container>
                        <Typography variant={'h3'} sx={{fontSize: {md: 30, xs: 24}}}>
                            Fragrance Capabilities
                        </Typography>
                        <Typography variant={'body1'} sx={{marginTop: '10px'}}>
                            Our fragrance technicians can encapsulate your essential fragrance oils, create a fragrance
                            for
                            you, or select one from the thousands of scents that are available. Prior to manufacturing,
                            The
                            Scent-It Palette will provide a scent rendition proof for your approval. Scent Marketing &
                            Advertising will give you an edge in ways that the Internet and electronic devices cannot
                            compete.
                        </Typography>
                        <Typography variant={'body1'} sx={{marginTop: '10px'}}>
                            You can provide your own fragrance or choose from our extensive collection of stock
                            fragrances
                            which includes:
                        </Typography>
                    </Grid>
                    <Grid container flexDirection={'row'} spacing={2}>
                        <Grid item  lg={6} md={6} sm={12} xs={12}>
                            <Table sx={{marginTop: {sm: '10px', xs: '10px'}, marginBottom: {sm: '10px', xs: '10px'}}}>
                                <TableHead sx={{fontWeight: 'bold'}}>
                                    General Fragrances
                                </TableHead>
                                <TableRow>
                                    <TableCell>All Naturals</TableCell>
                                    <TableCell>Leather</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Allspice</TableCell>
                                    <TableCell>Lemon</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Almond</TableCell>
                                    <TableCell>Lemon-Lime</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Amber</TableCell>
                                    <TableCell>Licorice</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Anise</TableCell>
                                    <TableCell>Lilac</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Apple Blossom</TableCell>
                                    <TableCell>Lily</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Apple</TableCell>
                                    <TableCell>Lime</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Apricot</TableCell>
                                    <TableCell>Magnolia</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Balsam</TableCell>
                                    <TableCell>Mango</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Banana</TableCell>
                                    <TableCell>Maple</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Bayberry</TableCell>
                                    <TableCell>Mimosa</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Bergamot Blossom</TableCell>
                                    <TableCell>Mint</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Bergamot</TableCell>
                                    <TableCell>Mood Themese</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Blueberry</TableCell>
                                    <TableCell>Muguet</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Bluebonnet</TableCell>
                                    <TableCell>Mulberry</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Bubble Gum</TableCell>
                                    <TableCell>Musk</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Butterscotch</TableCell>
                                    <TableCell>Myrrh</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Cantaloupe</TableCell>
                                    <TableCell>Narcissus</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Carnation</TableCell>
                                    <TableCell>Nature Themes</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Cedarwood</TableCell>
                                    <TableCell>New Mown Hay</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Cherry</TableCell>
                                    <TableCell>New Car Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Chocolate</TableCell>
                                    <TableCell>Ocean Themes</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Chypre</TableCell>
                                    <TableCell>Odor Masks</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Cinnamon</TableCell>
                                    <TableCell>Orange</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Citronella</TableCell>
                                    <TableCell>Orange Blossom</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Citrus</TableCell>
                                    <TableCell>Orchid</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Clove</TableCell>
                                    <TableCell>Oriental</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Clover</TableCell>
                                    <TableCell>Papaya</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Cocoa Butter</TableCell>
                                    <TableCell>Passion Fruit</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Coconut</TableCell>
                                    <TableCell>Patchouli</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Coffee</TableCell>
                                    <TableCell>Peach</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Cognac</TableCell>
                                    <TableCell>Peppermint</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Cookies</TableCell>
                                    <TableCell>Perfume Types</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Cranberry</TableCell>
                                    <TableCell>Pina Colada</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Cucumber</TableCell>
                                    <TableCell>Pine</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Cypress</TableCell>
                                    <TableCell>Pineapple</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Deodorant</TableCell>
                                    <TableCell>Plum</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Dewberry</TableCell>
                                    <TableCell>Plumeria</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Dutch Apple</TableCell>
                                    <TableCell>Potpourri</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Earth</TableCell>
                                    <TableCell>Powder Scent</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Environmental Themes</TableCell>
                                    <TableCell>Pumpkin</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Eucalyptus</TableCell>
                                    <TableCell>Rain Scent</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Flower Themes</TableCell>
                                    <TableCell>Raspberry</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Fougere</TableCell>
                                    <TableCell>Redwood</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Frangipanni</TableCell>
                                    <TableCell>Root Beer</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Frankincense</TableCell>
                                    <TableCell>Rose</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Freesia</TableCell>
                                    <TableCell>Rosewood</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Fresh & Clean</TableCell>
                                    <TableCell>Sandalwood</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Gardenia</TableCell>
                                    <TableCell>Sassafras</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Geranium</TableCell>
                                    <TableCell>Seasonal Themes</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Gingerbread</TableCell>
                                    <TableCell>Spearmint</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Grape</TableCell>
                                    <TableCell>Spice</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Grass</TableCell>
                                    <TableCell>Spring Fresh</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Guava</TableCell>
                                    <TableCell>Spruce</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Hazelnut</TableCell>
                                    <TableCell>Strawberry</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Heather</TableCell>
                                    <TableCell>Sweet Pea</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Heliotrope</TableCell>
                                    <TableCell>Tea Rose</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Herbal Types</TableCell>
                                    <TableCell>Tropical</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Holiday Themes</TableCell>
                                    <TableCell>Tuberose</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Hollyberry</TableCell>
                                    <TableCell>Tutti Fruitti</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Honey</TableCell>
                                    <TableCell>Vanilla</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Honeydew Melon</TableCell>
                                    <TableCell>Verbena</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Honeysuckle</TableCell>
                                    <TableCell>Violet</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Hyacinth</TableCell>
                                    <TableCell>Vetiver</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Incense</TableCell>
                                    <TableCell>Watermelon</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>International Themes</TableCell>
                                    <TableCell>Wintergreen</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Jasmine</TableCell>
                                    <TableCell>Wisteria</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Kiwi</TableCell>
                                    <TableCell>Woodland Themes</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Lavender</TableCell>
                                    <TableCell>Ylang Ylang</TableCell>
                                </TableRow>
                            </Table>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Table sx={{marginTop: {sm: '10px', xs: '10px'}, marginBottom: {sm: '10px', xs: '10px'}}}>
                                <TableHead sx={{fontWeight: 'bold'}}>
                                    Brand Names
                                </TableHead>
                                <TableRow>
                                    <TableCell>Actif Blue Type</TableCell>
                                    <TableCell>Lysol Mountain Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Agree Shampoo Type</TableCell>
                                    <TableCell>Matrix Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Aqua Net Type</TableCell>
                                    <TableCell>Mennen Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Aqua Velva Type</TableCell>
                                    <TableCell>Mr. Clean Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Aussie Type</TableCell>
                                    <TableCell>Murphy Oil Soap Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Aveda Shampure Type</TableCell>
                                    <TableCell>NABC Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Baby Magic Type</TableCell>
                                    <TableCell>Neutrogena Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Bath & Body Works Type</TableCell>
                                    <TableCell>Neutroeum Gamma Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Beauty Lac Hair Spray Type</TableCell>
                                    <TableCell>Nexxus Types</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Big D Lemon</TableCell>
                                    <TableCell>Niagara Spray Starch Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Body On Tap Type</TableCell>
                                    <TableCell>Nivea Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Boraxo Type</TableCell>
                                    <TableCell>Oil of Olay Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Bounce Type</TableCell>
                                    <TableCell>Opium type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Breck Hair Spray Type</TableCell>
                                    <TableCell>Origins Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Camay Type</TableCell>
                                    <TableCell>Palmolive Dish Soap Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Carmex Type</TableCell>
                                    <TableCell>Pantene Pro-V Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Cello Shampoo Type</TableCell>
                                    <TableCell>Paul Mitchell Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Clairmist Regular Type</TableCell>
                                    <TableCell>Pert Shampoo Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Clairol Herbal Essence Type</TableCell>
                                    <TableCell>PineSol Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Clorox Fresh Scent Type</TableCell>
                                    <TableCell>Plentitude Excel A3 Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Coast Type</TableCell>
                                    <TableCell>Pond's Alpha Hydroxy Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Comet Type</TableCell>
                                    <TableCell>Preference Shampoo Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Coppertone Type</TableCell>
                                    <TableCell>Purex Brillo Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Curel Type</TableCell>
                                    <TableCell>Redken Intervals Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Dawn Type</TableCell>
                                    <TableCell>Resolve Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Degree Powder Fresh Type</TableCell>
                                    <TableCell>Right Guard Original Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Dial Type</TableCell>
                                    <TableCell>Right Guard Sports Fresh Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Dove Type</TableCell>
                                    <TableCell>Sea Breeze Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Downy Type</TableCell>
                                    <TableCell>Sea & Ski Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Estee Fruition Type</TableCell>
                                    <TableCell>Seaweed & Arnica Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Finesse Type</TableCell>
                                    <TableCell>Skin So Soft Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Flex Balsam Type</TableCell>
                                    <TableCell>Skol Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Gain Laundry Type</TableCell>
                                    <TableCell>Smart Scrub Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Glade Types</TableCell>
                                    <TableCell>Snuggle Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Head & Shoulders Type</TableCell>
                                    <TableCell>Soft & Dri Baby Powder Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Herbal Balsam Type</TableCell>
                                    <TableCell>Softsoap Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Infusium 3 Type</TableCell>
                                    <TableCell>St. Ives Swiss Aloe Vera Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Irish Spring Type</TableCell>
                                    <TableCell>Sure Pro Stick Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Ivory Type</TableCell>
                                    <TableCell>Surf Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>J & J Baby Powder Type</TableCell>
                                    <TableCell>Swan Soap Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>J & J Shampoo Type</TableCell>
                                    <TableCell>Tide Types</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Jergens Type</TableCell>
                                    <TableCell>Tigi Hair Spray Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Juniper with Aloe Type</TableCell>
                                    <TableCell>Toilet Duck Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Karacel Type</TableCell>
                                    <TableCell>Tropical Blend Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Kerex Type</TableCell>
                                    <TableCell>Vaseline Intensive Care Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Keri Lotion Type</TableCell>
                                    <TableCell>VaVoom Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Koya Cream Type</TableCell>
                                    <TableCell>Vitabath Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Lectric Shave Type</TableCell>
                                    <TableCell>Wheatgerm & Honey Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Lemon Joy Type</TableCell>
                                    <TableCell>Wisk Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Lever 2000 Type</TableCell>
                                    <TableCell>Wondra Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Lifebuoy type</TableCell>
                                    <TableCell>Yankee Candle Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Lux Type</TableCell>
                                    <TableCell>Yardley Lavender Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Lysol Country Scent Type</TableCell>
                                    <TableCell>Zest Type</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Lysol Fresh Scent Type</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>


            </Card>
        </Container>
    )
}

export default Fragrances;